import { useState, useEffect, useCallback } from "react";
import { Contract, ethers } from "ethers";
import { CONTRACT_ADDRESSES, ABIS } from "../utils/constants";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { toast } from "react-toastify";
import { parseUnits } from 'viem'


export const useContract = (signer) => {
  const [usdtContract, setUsdtContract] = useState(null);
  const [presaleContract, setPresaleContract] = useState(null);
  const [vestingContract, setVestingContract] = useState(null);
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const [proofAddress, setProofAddress] = useState(null);
  const { isConnected, address: wallet_address, connector } = useAccount();
  const network = localStorage.getItem("network");
  const { data: allowancevalue, refetch: refetchAllowance } = useReadContract({
    abi: ABIS.ERC20_CONTRACT_ABI,
    address:  CONTRACT_ADDRESSES[network]?.USDT_TOKEN,
    functionName: 'allowance',
    args: [ CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT, wallet_address]
  })
  const { writeContract, isLoading, isSuccess, isError, error, data, isPaused, isPending } = useWriteContract()
  // // console.log("allowancevalue", isLoading, isSuccess, isError, error, data, isPaused, isPending)
  // useEffect(() => {
  //   if(!allowancevalue && !parseInt(allowancevalue?.toString())){
  //     Promise.all([refetchAllowance()])
  //       .then(([]) => {
  //         console.log('Allownce successfully!');
  //       });
  //   } 
  //   if(data && isSuccess && loading) {
  //     writeContract({
  //       abi: ABIS.PRESALE_CONTRACT_ABI,
  //       address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
  //       functionName: 'buyTokens', 
  //       args: [values, proofAddress],
  //       value: values
  //     })
  //     setLoading(false);
  //   }
  //   if(isSuccess && data &&  values && !loading) {
  //     toast.success("Tokens purchased successfully");
  //   }
  // }, [isSuccess, data, loading, allowancevalue, values])

  useEffect(() => {
    const setupContracts = async () => {
      const ERC20_CONTRACT_ADDRESS = CONTRACT_ADDRESSES[network]?.USDT_TOKEN;
      const PRESALE_CONTRACT_ADDRESS =
        CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT;
      const VESTING_CONTRACT_ADDRESS =
        CONTRACT_ADDRESSES[network]?.VESTING_CONTRACT;
      if (signer) {
        setUsdtContract(
          new Contract(ERC20_CONTRACT_ADDRESS, ABIS.ERC20_CONTRACT_ABI, signer)
        );
        setPresaleContract(
          new Contract(
            PRESALE_CONTRACT_ADDRESS,
            ABIS.PRESALE_CONTRACT_ABI,
            signer
          )
        );
        setVestingContract(
          new Contract(
            VESTING_CONTRACT_ADDRESS,
            ABIS.VESTING_CONTRACT_ABI,
            signer
          )
        );
      }
    };
    setupContracts();
  }, [signer]);

  const fetchUsdtBalance = async (walletAddress, chainNetwork) => {
    const network = chainNetwork || localStorage.getItem("network");
    console.log("usdtContractTron", window.tronWeb && window.tronWeb.ready)
    if (network === "tron") {
      // TRON
      try {
        let tronWebBrowser;
        if (window.tronWeb && window.tronWeb.ready) {
          tronWebBrowser = window.tronWeb;
        } else {
          console.error("TronLink not found or not ready");
          return 0;
        }

        const usdtContractTron = await tronWebBrowser
          .contract()
          .at(CONTRACT_ADDRESSES.tron.USDT_TOKEN);

        const decimals = CONTRACT_ADDRESSES[network]?.DECIMALS || 6;
        console.log("usdtContractTron", usdtContractTron)
        const rawBalance = await usdtContractTron
          .balanceOf(walletAddress)
          .call();

        const balance = Number(rawBalance?._hex ?? rawBalance) / 10 ** decimals;

        return balance.toString();
      } catch (error) {
        console.error("Error fetching usdt balance on Tron:", error);
        return 0;
      }
    } else {
      //  EVM
      if (usdtContract) {
        try {
          const rawBalance = await usdtContract.balanceOf(walletAddress);
          return ethers.formatEther(rawBalance);
        } catch (error) {
          console.error("Error fetching usdt balance:", error);
          return 0;
        }
      } else {
        return 0;
      }
    }
  };

  const fetchTokenBalance = async (walletAddress) => {
    if (usdtContract) {
      try {
        return ethers.formatEther(await usdtContract.balanceOf(walletAddress));
      } catch (error) {
        console.error("Error fetching eth balance:", error);
      }
    }
  };

  const addressToUsdt = useCallback(
    async (address, chainNetwork) => {
      const network = chainNetwork || localStorage.getItem("network");

      // Tron
      if (network === "tron") {
        try {
          let tronWebBrowser;
          if (window.tronWeb && window.tronWeb.ready) {
            tronWebBrowser = window.tronWeb;
          } else {
            console.error("TronLink not found or not ready");
            return 0;
          }

          const presaleContractTron = await tronWebBrowser
            .contract()
            .at(CONTRACT_ADDRESSES.tron.PRESALE_CONTRACT);

          const decimals = CONTRACT_ADDRESSES[network]?.DECIMALS || 6;

          const rawBalance = await presaleContractTron
            .addressToUsdt(address)
            .call();

          const numericBalance =
            Number(rawBalance?._hex ?? rawBalance) / 10 ** decimals;

          return numericBalance.toString();
        } catch (error) {
          console.log("Error while fetching Sold Tokens on Tron", error);
          return 0;
        }
      }
      // EVM
      else {
        if (presaleContract) {
          try {
            const rawBalance = await presaleContract.addressToUsdt(address);
            return ethers.formatEther(rawBalance);
          } catch (error) {
            console.log("Error while fetching Sold Tokens", error);
            return 0;
          }
        }
        return 0;
      }
    },
    [presaleContract]
  );

  const fetchTokenPrice = async () => {
    if (presaleContract) {
      try {
        return ethers.formatEther(await presaleContract.tokenPrice());
      } catch (error) {
        console.log("Error fetching token price");
      }
    }
  };

  const fetchVestingInfo = useCallback(
    async (walletAddress) => {
      if (vestingContract && walletAddress) {
        try {
          const [totalTokens, claimedTokens] =
            await vestingContract.beneficiaries(walletAddress);
          return {
            totalTokens: ethers.formatEther(totalTokens),
            claimedTokens: ethers.formatEther(claimedTokens),
          };
        } catch (error) {
          console.error("Error fetching vesting info:", error);
          return { totalTokens: 0, claimedTokens: 0 };
        }
      }
    },
    [vestingContract]
  );

  const fetchUnlockedTokens = useCallback(
    async (walletAddress) => {
      if (vestingContract && walletAddress) {
        try {
          const unlockedTokens = await vestingContract.getUnlockedTokens(
            walletAddress
          );
          return ethers.formatEther(unlockedTokens);
        } catch (error) {
          console.error("Error fetching unlocked tokens:", error);
          return 0;
        }
      }
    },
    [vestingContract]
  );

  const hasVestingStarted = useCallback(async () => {
    if (vestingContract) {
      try {
        return await vestingContract.vestingStart();
      } catch (error) {
        console.error("Error fetching unlocked tokens:", error);
        return 0;
      }
    }
  }, [vestingContract]);

  const getTotalRaisedBnb = useCallback(async () => {
    if (presaleContract) {
      try {
        const soldTokens = await presaleContract.totalRaisedBnb();
        return ethers.formatEther(soldTokens);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const getBnbCap = useCallback(async () => {
    if (presaleContract) {
      try {
        const totalCap = await presaleContract.totalCapBnb();
        return ethers.formatEther(totalCap);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const getMaxPurchaseAmount = useCallback(async () => {
    if (presaleContract) {
      try {
        const maxPurchase = await presaleContract.maxPurchase();
        return ethers.formatEther(maxPurchase);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const getMinPurchaseAmount = useCallback(async () => {
    if (presaleContract) {
      try {
        const minPurchase = await presaleContract.minPurchase();
        return ethers.formatEther(minPurchase);
      } catch (error) {
        console.log("Error while fetching Sold Tokens", error);
        return;
      }
    }
  }, [presaleContract]);

  const buyTokens = useCallback(
    async (usdtValue, proof) => {
      const walletType = localStorage.getItem('walletConnect');
      const network = localStorage.getItem("network");
      const decimals = CONTRACT_ADDRESSES[network]?.DECIMALS;
      const presaleContractAddress =
        CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT;

      if (network === "tron") {
      debugger
        try {
          let tronWebBrowser;
          if (window.tronWeb && window.tronWeb.ready) {
            tronWebBrowser = window.tronWeb;
          } else {
            console.error("TronLink not found or not ready");
            return { status: false, message: "Please connect TronLink wallet" };
          }

          const formattedValue = (
            usdtValue * Math.pow(10, decimals)
          ).toString();

          try {
            const usdtContractTron = await tronWebBrowser
              .contract()
              .at(CONTRACT_ADDRESSES.tron.USDT_TOKEN);

            const presaleContractTron = await tronWebBrowser
              .contract()
              .at(presaleContractAddress);

            try {
              const allowance = await usdtContractTron
                .allowance(
                  tronWebBrowser.defaultAddress.base58,
                  presaleContractAddress
                )
                .call();

              if (parseInt(allowance.toString()) < parseInt(formattedValue)) {
                const approveTx = await usdtContractTron
                  .approve(presaleContractAddress, formattedValue)
                  .send({
                    feeLimit: 100000000,
                  });
                console.log("Approve transaction hash:", approveTx);
              }

              const buyTx = await presaleContractTron
                .buyTokens(formattedValue, proof)
                .send({
                  feeLimit: 100000000,
                });
              console.log("Buy transaction hash:", buyTx);

              return { status: true, message: "Purchase successful" };
            } catch (txError) {
              console.error("Transaction failed:", txError);
              return {
                status: false,
                message:
                  txError.message || "Transaction failed. Please try again.",
              };
            }
          } catch (contractError) {
            console.error("Contract interaction failed:", contractError);
            return {
              status: false,
              message:
                contractError.message || "Failed to interact with contract",
            };
          }
        } catch (error) {
          console.error("General error:", error);
          return {
            status: false,
            message:
              "Transaction failed. Please check your wallet and try again.",
          };
        }
      } 
      // else if (signer && presaleContract && !walletType) {
      //   try {
      //     const formattedValue = ethers.parseUnits(usdtValue, decimals);

      //     // Check allowance
      //     let allowance = await usdtContract.allowance(
      //       await signer.getAddress(),
      //       presaleContractAddress
      //     );
      //     allowance = ethers.formatEther(allowance);

      //     if (allowance < usdtValue) {
      //       const approveTx = await usdtContract.approve(
      //         presaleContractAddress,
      //         formattedValue
      //       );
      //       await approveTx.wait();
      //     }

      //     const transaction = await presaleContract.buyTokens(
      //       formattedValue,
      //       proof
      //     );
      //     await transaction.wait();

      //     return { status: true };
      //   } catch (error) {
      //     console.error("Error during EVM buyTokens:", error);
      //     return { status: false, message: error.message };
      //   }
      // } 
      else {
        const formattedValue = parseUnits(usdtValue, decimals);
        if(parseInt(allowancevalue?.toString()) < parseInt(formattedValue)){
          writeContract({
            abi: ABIS.ERC20_CONTRACT_ABI,
            address: CONTRACT_ADDRESSES[network]?.USDT_TOKEN,
            functionName: 'approve', 
            args: [CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT, formattedValue],
            value: formattedValue
          })
          setValues(formattedValue)
          setLoading(true);
          setProofAddress(proof)
        }
      }
    },
    [signer, presaleContract, usdtContract]
  );

  const claimTokens = useCallback(async () => {
    if (signer && vestingContract) {
      try {
        const transaction = await vestingContract.claimTokens();
        await transaction.wait();
        return { status: true };
      } catch (error) {
        return { status: false, message: error.message };
      }
    }
  }, [signer, vestingContract]);

  return {
    usdtContract,
    presaleContract,
    vestingContract,
    fetchUsdtBalance,
    fetchTokenBalance,
    addressToUsdt,
    fetchTokenPrice,
    buyTokens,
    fetchVestingInfo,
    fetchUnlockedTokens,
    hasVestingStarted,
    claimTokens,
    getTotalRaisedBnb,
    getBnbCap,
    getMaxPurchaseAmount,
    getMinPurchaseAmount,
  };
};

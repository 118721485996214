import erc20Abi from "../web3/erc20.json";
import presaleContractAbi from "../web3/presale.json";
import vestingContractAbi from "../web3/vesting.json";

export const CONTRACT_ADDRESSES = {
  bsc: {
    DECIMALS: 18,
    WOW_TOKEN: "0x181Af90a5dB66Bd5B8cB6EF9A4e0463Dc877bd7b",
    USDT_TOKEN: "0x96c8Ed4C38E790048502376b253dd8F7B32C75dD",
    PRESALE_CONTRACT: "0x569E5244036B6300344B734Ba5e58C625C11228d",
    VESTING_CONTRACT: "0x407Bcbe88BD55BaF91F4e92790185f66020505e7",
  },
  ethereum: {
    DECIMALS: 6,
    WOW_TOKEN: "0xe24A6b49574eA20331a983cB4f3D7D91770a4400",
    USDT_TOKEN: "0xd272FA6089A3e2e9B9b7B15dfd35375432B76c94",
    PRESALE_CONTRACT: "0x284DF7d80a48D8d50338D3993B64579ADE97C01c",
    VESTING_CONTRACT: "0x8EBAe93DfE8Ab7DCeA424edDec3F961a783eD34e",
  },
  tron: {
    DECIMALS: 18,
    WOW_TOKEN: "TFfEXZA5UcFXsv2oGCUuVyRu16owKadZg1",
    USDT_TOKEN: "TNuXiZ5Qy1C8ixhYnBRycedgtN1QAs87gs",
    PRESALE_CONTRACT: "THWr6EZ6xrhDrBXd5AqqimCKpty8igChyZ",
    VESTING_CONTRACT: "THV5DMY8wSYP7ut6CRNSgKsD3QFNF2PG2o",
  },
};

export const API_BASE_URL = "https://api.dev.presale.evolalabs.com/api/";

export const ABIS = {
  ERC20_CONTRACT_ABI: erc20Abi,
  PRESALE_CONTRACT_ABI: presaleContractAbi,
  VESTING_CONTRACT_ABI: vestingContractAbi,
};

export const PRESALE_START_DATE = 1730419200000; // Friday, November 1, 2024 12:00:00 AM
export const PRESALE_END_DATE = 1735689599000; // Tuesday, 31 December 2024 23:59:59 UTC

export const VESTING_START_DATE = 1735689600000; // Wednesday, January 1, 2025 12:00:00 AM
export const VESTING_END_DATE = 1751241600000; // Monday, June 30, 2025 12:00:00 AM

"use client";
import React from "react";
import { toast } from "react-toastify";
import { WalletButton } from "@rainbow-me/rainbowkit";
import { CloseIcon } from "../assets";
import walletConnectIcon from "../images/walletConnect.jpg";
import metamask from "../images/metamask.png";
import TronWalletConnect from "./TronWalletConnect";

export default function WalletModal({ setShowWalletModal, connectMetamask }) {
  const selectedNetwork = localStorage.getItem("network") || "ethereum"
  console.log("selectedNetwork", selectedNetwork)
  return (
    <React.Fragment>
      <div className="app-modal">
        <div className="wallet-wrapper justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[110] outline-none focus:outline-none">
          <div className="relative w-full h-auto flex justify-center">
            <div className="mt-28 md:mt-10 absolute md:relative max-[768px]:w-[90%] mx-auto border-0 rounded-3xl shadow-modal flex flex-col w-[450px] bg-[#041e19] outline-none focus:outline-none">
              {/*header*/}
              <button
                className="close-modal absolute right-[20px] top-[12px]"
                onClick={() => setShowWalletModal(false)}
              >
                <CloseIcon />
              </button>
              <div className="modal-content rounded-tl-2xl rounded-tr-2xl md:rounded-2xl px-[20px] py-3 mt-4">
                <div className="mt-4 mb-11">
                  <h2 className="text-[32px] text-center font-bold text-white">
                    Connect Wallet
                  </h2>
                  <p className="text-[18px] text-center text-white">
                    Select your favorite wallet to log in.
                  </p>
                </div>
                <div className="mb-6">
                  {/* <button className="flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6">
                    <div className="flex items-center gap-4 font-semibold text-white">
                      <img className="w-10" src={logo} alt="Media" />
                      <span>WOW Wallet</span>
                    </div>
                    <div className="text-xs rounded py-1 px-4 text-black bg-primary-gradient font-medium">
                      Popular
                    </div>
                  </button> */}

                  {/* <button
                    onClick={async () => {
                         const selectedNetwork = localStorage.getItem("network");
                         if (selectedNetwork === "tron") {
                           toast.error("Cannot connect to MetaMask. You have selected TRON network. Please switch network or use Tron wallet.");
                           return;
                         }
                      
                          await connectMetamask(localStorage.getItem("network"));
                          setShowWalletModal(false);
                        }}
                    className="flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6"
                  >
                    <div className="flex items-center gap-4 font-semibold text-white">
                      <img className="w-10" src={metamask} alt="Media" />
                      <span>Metamask</span>
                    </div>
                  </button> */}

                  <WalletButton.Custom wallet="metamask">
                    {({ ready, connect }) => (
                      <button
                        onClick={() => {
                          if (selectedNetwork === 'tron') {
                            toast.error('Your selected network is Tron. Please select TronLink wallet!')
                          } else {
                            connect();
                            setShowWalletModal(false);
                          }
                        }}
                        className="flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6"
                      >
                        <div className="flex items-center gap-4 font-semibold text-white">
                          <img className="w-10" src={metamask} alt="Media" />
                          <span>Metamask</span>
                        </div>
                      </button>
                    )}
                  </WalletButton.Custom>

                  <WalletButton.Custom wallet="walletConnect">
                    {({ ready, connect }) => (
                      <button
                        onClick={() => {
                          // if(selectedNetwork === 'tron'){
                          //   toast.error('Your selected network is Tron. Please select TronLink wallet!')
                          // } else {
                          localStorage.setItem('walletContactTron', 'tron')
                          connect();
                          setShowWalletModal(false);
                          // }
                        }}
                        className="flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6"
                      >
                        <div className="flex items-center gap-4 font-semibold text-white">
                          <img
                            className="w-10 rounded"
                            src={walletConnectIcon}
                            alt="Media"
                          />
                          <span>Wallet Connect</span>
                        </div>
                      </button>
                    )}
                  </WalletButton.Custom>

                  {/* <TronWalletConnect
                    handleClose={() => setShowWalletModal(false)}
                  /> */}
                  {/* <button
                    onClick={() => tronConnect()}
                    className="flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6"
                  >
                    <div className="flex items-center gap-4 font-semibold text-white">
                      <img src='https://lh3.googleusercontent.com/1BPbdzIDWE2RYHDIZV3K--KG2VWgS_R8yjs2ZVLvoVnAhpCMWZPwJGJ2Q5PjXbVXUMj7u6DUh-Z7wQ9vJVf37d-h=s60' className='w-10' alt='Media' />
                      <span>Tron Link</span>
                    </div>
                  </button> */}
                </div>
                {/*body*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Connect Wallet Modal Ends Here */}
    </React.Fragment>
  );
}

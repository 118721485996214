import React from "react";
import Progressbar from "./Progressbar";
import { CountDown } from "./CountDown";
import { VESTING_END_DATE } from "../utils/constants";

export default function ClaimToken({
  walletAddress,
  lockedTokens,
  claimedTokens,
  claimableTokens,
  onClaimHandler = () => { },
  onConnectHandler = () => { },
}) {
  claimableTokens = Number(claimableTokens);

  return (
    <div className="vesting-container">
      <div className="bg-[#265d4e] mt-4 py-5 md:py-7 px-5 md:px-9 rounded-xl">
        <p className="text-center digi-font text-xl font-bold text-white">
          Vesting Ends In
        </p>
        <CountDown endDate={VESTING_END_DATE} />
        {/* <Progressbar /> */}
        <h1 className="text-xl  mb-3 font-bold text-white text-center">
          Claim Your WOW Tokens
        </h1>

        <div className="rounded-xl border-2 border-wow px-4 md:px-6 py-3 md:py-4">
          <div className="flex justify-between items-center mb-2">
            <p className="text-sm font-bold text-white">Total locked:</p>
            <p className="text-base font-bold text-white flex gap-1 items-center">
              {lockedTokens}
            </p>
          </div>

          <div className="flex justify-between items-center mb-2">
            <p className="text-sm font-bold text-white">Total claimed:</p>
            <p className="text-base font-bold text-white flex gap-1 items-center">
              {claimedTokens}
            </p>
          </div>

          <div className="flex justify-between items-center">
            <p className="text-sm font-bold text-white">Available to claim:</p>
            <p className="text-base font-bold text-white flex gap-1 items-center">
              {claimableTokens}
            </p>
          </div>
        </div>

        <div>
          {walletAddress ? (
            <button
              disabled={!claimableTokens}
              onClick={onClaimHandler}
              className={`p-3 ${!claimableTokens ? "bg-gray-500" : "bg-hover-gradient"
                }  ${!!claimableTokens && "hover:shadow-pump"
                } min-h-11 rounded-xl w-full mt-6 text-base font-bold text-white`}
            >
              {!!claimableTokens ? "CLAIM" : "No tokens to Claim"}
            </button>
          ) : (
            <button
              onClick={onConnectHandler}
              className="p-3 bg-hover-gradient hover:shadow-pump min-h-11 rounded-xl w-full mt-6 text-base font-bold text-white"
            >
              CONNECT WALLET
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Timer from "./pages/Timer";
import { Context } from "./utils/context";

function App() {
  return (
    <div className="font-mono">
        <Context>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/timer" element={<Timer />} />
            </Routes>
          </BrowserRouter>
        </Context>
    </div>
  );
}

export default App;

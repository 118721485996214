import React, { useState, useEffect } from "react";
import { CloseIcon } from "../assets";

export default function ComingSoonModal({ setShowComingSoonModal }) {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    // Set the target date: March 10th, 12PM UTC
    const targetDate = new Date('2025-03-10T12:00:00Z');

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;
      if (difference <= 0) {
        setShowComingSoonModal(false);
        return null;
      }

      // Calculate days, hours, minutes, seconds
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      };
    };

    // Calculate initial time left
    const initialTimeLeft = calculateTimeLeft();
    if (initialTimeLeft) {
      setTimeLeft(initialTimeLeft);
    }

    // Set up interval to update the countdown every second
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft();
      if (updatedTimeLeft) {
        setTimeLeft(updatedTimeLeft);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, [setShowComingSoonModal]);

  return (
    <React.Fragment>
      <div className={timeLeft.seconds > 0 || timeLeft.minutes > 0 || timeLeft.hours > 0 || timeLeft.days > 0 ? "app-modal" : "hidden"}>
        <div className="wallet-wrapper justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[110] outline-none focus:outline-none">
          <div className="relative w-full h-auto flex justify-center">
            <div className="mt-28 md:mt-10 absolute md:relative max-[768px]:w-[90%] mx-auto border-0 rounded-3xl shadow-modal flex flex-col w-[450px] bg-[#041e19] outline-none focus:outline-none">
              {/*header*/}
              {/* <button
                className="close-modal absolute right-[20px] top-[12px]"
                onClick={() => setShowComingSoonModal(false)}
              >
                <CloseIcon />
              </button> */}
              <div className="modal-content rounded-tl-2xl rounded-tr-2xl md:rounded-2xl px-[20px] py-3 mt-4">
                <div className="mt-4 mb-5">
                  <h2 className="text-2xl mb-1 text-center font-bold text-white">
                    We are coming soon
                  </h2>
                  <p className="text-[18px] text-center text-white">
                    STAY TUNED!
                  </p>
                </div>
                <div className="mb-6">
                  <div className="grid grid-cols-4 gap-3 md:gap-5 mt-5 mb-8 digi-font">
                    <div className="p-1 rounded-md shadow-pump">
                      <div className="bg-wow text-base text-center md:text-xl tracking-[2px] py-1 rounded-[5px] font-bold text-black">
                        {String(timeLeft.days).padStart(2, '0')}
                        <p className="text-center text-[11px] text-black uppercase font-semibold">
                          {timeLeft.days === 1 ? "Day" : "Days"}
                        </p>
                      </div>
                    </div>
                    <div className="p-1 rounded-md shadow-pump">
                      <div className="bg-wow text-base text-center md:text-xl tracking-[2px] py-1 rounded-[5px] font-bold text-black">
                        {String(timeLeft.hours).padStart(2, '0')}
                        <p className="text-center text-[11px] text-black uppercase font-semibold">
                          hrs
                        </p>
                      </div>
                    </div>
                    <div className="p-1 rounded-md shadow-pump">
                      <div className="bg-wow text-base text-center md:text-xl tracking-[2px] py-1 rounded-[5px] font-bold text-black">
                        {String(timeLeft.minutes).padStart(2, '0')}
                        <p className="text-center text-[11px] text-black uppercase font-semibold">
                          mins
                        </p>
                      </div>
                    </div>
                    <div className="shadow-pump p-1 rounded-md">
                      <div className="bg-wow text-base text-center md:text-xl tracking-[2px] py-1 rounded-[5px] font-bold text-black">
                        {String(timeLeft.seconds).padStart(2, '0')}
                        <p className="text-center text-[11px] text-black uppercase font-semibold">
                          secs
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/*body*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
import React, { useEffect, useState } from "react";
import { createPublicClient, http } from 'viem';
import { bscTestnet, baseSepolia } from 'viem/chains';
import { parseUnits } from 'viem'
import { MaxUint256, ethers } from "ethers";
import { toast } from "react-toastify";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

import logo from "./../images/logo.svg";
import usdt from "./../images/usdt.png";
import { CONTRACT_ADDRESSES, ABIS } from "../utils/constants";

import { Input } from "../components/Input";
import { CustomProgressBar, HelpIcon, InfoIcon, LoadingIcon, LogoutIcon } from "../assets";
import { MiddleTruncate } from "../components/Header";

import WalletModal from "../components/WalletModal";
import ComingSoonModal from "../components/ComingSoonModal";
import ClaimToken from "../components/ClaimToken"
import { useWalletMetaMask } from "../hooks/useWallet";
import { useContract } from "../hooks/useContract";
import { API_BASE_URL } from "../utils/constants";
import { useAccount, useBalance, useConnect, useDisconnect, useReadContract, useSwitchChain, useTransactionReceipt, useWriteContract } from "wagmi";
import { useToast } from "../hooks/ToastProvider";

export default function LandingPage() {
  const {
    connected,
    address,
    disconnect,
  } = useWallet();
  const [showComingSoonModal, setShowComingSoonModal] = useState(true);

  const { connect, connectors } = useConnect();
  const showToast = useToast();
  const { isConnected, address: wallet_address, connector } = useAccount();
  const { disconnect: disconnectWalletConnect } = useDisconnect();

  const { data: currentBalanceBSC, refetch } = useBalance({ address: wallet_address, token: "0x96c8Ed4C38E790048502376b253dd8F7B32C75dD", watch: true });
  const { data: currentBalanceETH } = useBalance({ address: wallet_address, token: "0xd272FA6089A3e2e9B9b7B15dfd35375432B76c94", watch: true });
  const { switchChain } = useSwitchChain();
  const [network, setNetwork] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [proof, setProof] = useState(null);
  const [values, setValues] = useState(null);
  const [call, setCall] = useState(false);
  const [vesting, setVesting] = useState(false);
  const [loadingRound, setLoadingRound] = useState(true);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [amountInvested, setAmountInvested] = useState({
    usdt: 0,
    wow: 0,
  });
  const [remainingTime, setRemainingTime] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [whitelistCountdown, setWhitelistCountdown] = useState(0);
  const [whitelistStatusMessage, setWhitelistStatusMessage] = useState("");


  const [usdtValue, setUsdtValue] = useState();
  const [wowValue, setWowValue] = useState(0);
  const [roundData, setRoundData] = useState([]);
  const [roundCount, setRoundCount] = useState(null);
  const [roundLoader, setRoundLoader] = useState(true);

  const { writeContract, isLoading, isSuccess, isError, error: writeError, data: writeData, isPaused, isPending, isIdle } = useWriteContract();
  const {
    data: receipt,
    isLoading: isConfirming,
    isSuccess: isConfirmed,
  } = useTransactionReceipt({
    hash: writeData,
  });

  const { data: allowancevalue, refetch: refetchAllowance } = useReadContract({
    abi: ABIS.ERC20_CONTRACT_ABI,
    address: CONTRACT_ADDRESSES[network]?.USDT_TOKEN,
    functionName: 'allowance',
    args: [wallet_address, CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT]
  })

  const { data: currentvalue, refetch: refetchCurrent, error: currentErr } = useReadContract({
    abi: ABIS.PRESALE_CONTRACT_ABI,
    address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
    functionName: 'addressToUsdt',
    args: [wallet_address]
  })

  const { data: roundvalue, refetch: refetchRound, error: roundErr } = useReadContract({
    abi: ABIS.PRESALE_CONTRACT_ABI,
    address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
    functionName: 'currentRound',
  })

  const { data: roundActive, refetch: refetchRoundActive, error: roundErrActive, isLoading: roundActiveLoading } = useReadContract({
    abi: ABIS.PRESALE_CONTRACT_ABI,
    address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
    functionName: 'rounds',
    args: [roundvalue]
  })

  const publicProviders = {
    bsc: createPublicClient({
      chain: bscTestnet,
      transport: http('https://data-seed-prebsc-1-s1.binance.org:8545/')
    }),
    ethereum: createPublicClient({
      chain: baseSepolia,
      transport: http('https://sepolia.base.org')
    })
  };

  useEffect(() => {
    setRoundLoader(true)
    // Only fetch data when not connected to wallet
    if (!isConnected) {
      const fetchRoundData = async () => {
        try {
          const provider = publicProviders[network];
          const presaleContract = {
            address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
            abi: ABIS.PRESALE_CONTRACT_ABI,
          };
          const data = await provider.readContract({
            ...presaleContract,
            functionName: 'currentRound'
          });
          const dataInfo = await provider.readContract({
            ...presaleContract,
            functionName: 'rounds',
            args: [data]
          });
          setRoundData(dataInfo)
          setRoundCount(data)
          setRoundLoader(false);
        } catch (error) {
          console.error("Error fetching round data:", error);
        }
      };
      fetchRoundData();
    } else if(isConnected && roundActive && roundvalue){
      setRoundData(roundActive)
      setRoundCount(Number(roundvalue))
      setRoundLoader(false);
    }
  }, [isConnected, network, roundActive, roundvalue, roundLoader]); 

  let startTime = roundData?.[6];
  let endTime = roundData?.[7]
  let currentRoundActive = roundData?.[5];

  useEffect(() => {
    refetchRound();
    if (roundCount !== undefined) {
      refetchRoundActive();
      setLoadingRound(false);
    }

    const intervalId = setInterval(() => {
      refetchRound();
      if (roundCount !== undefined) {
        refetchRoundActive();
      }
    }, 5000); // 1000ms = 1 second

    return () => clearInterval(intervalId);
  }, [refetchRound, refetchRoundActive, roundCount, network]);

  useEffect(() => {
    // if (!allowancevalue && !parseInt(allowancevalue?.toString())) {
    Promise.all([refetchAllowance(), refetchCurrent()])
      .then(([]) => {
        console.log('Allownce successfully!');
      });
    // }
  }, [allowancevalue, call])

  useEffect(() => {
    if (writeData && isConfirmed && loading && call) {
      writeContract({
        abi: ABIS.PRESALE_CONTRACT_ABI,
        address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
        functionName: 'buyTokens',
        args: [values, whitelistCountdown > 0 ? proof : whitelistCountdown === 0 ? proof ? proof : [] : []],
        // value: values
      })
      setCall(false);
    } else if (isError && loading && !isPending) {
      setLoading(false);
      setCall(false);
      showToast(writeError?.shortMessage ? writeError?.shortMessage : "Transaction Failed!", 'error')
      // toast.error(writeError?.shortMessage ? writeError?.shortMessage : "Transaction Failed!")
    }
    if (isConfirmed && writeData && loading && !call) {
      showToast('Tokens purchased successfully!', 'success')
      // toast.success("Tokens purchased successfully!");
      setLoading(false);
      setUsdtBalance(usdtBalance - usdtValue)
      setAmountInvested({ usdt: amountInvested?.usdt + Number(usdtValue), wow: amountInvested?.wow + Number(usdtValue) * 100 });
      setData({
        ...data,
        totalRaisedUsdt: Number(data.totalRaisedUsdt) + Number(usdtValue),
        totalRaisedWow: Number(data.totalRaisedWow) + Number(wowValue),
        stageData: {
          ...data.stageData,
          [roundCount]: {
            ...data.stageData[roundCount],
            usdtRaised: Number(data.stageData[roundCount]?.usdtRaised || 0) + Number(usdtValue)
          }
        }
      });
    }
    refetch()
  }, [isConfirmed, writeData, loading, allowancevalue, values, call, isError, writeError])

  const [data, setData] = useState({
    totalCapUsdt: 0,
    totalRaisedUsdt: 0,
    totalCapWow: 0,
    totalRaisedWow: 0,
    minimumPurchaseUsdt: 0,
    tokenPrice: 0,
  });

  useEffect(() => {
    setNetwork(localStorage.getItem("network") || "ethereum");
  }, []);

  const [showWalletModal, setShowWalletModal] = useState(false);
  const [isAddressWhitelisted, setIsAddressWhitelisted] = useState(true);
  const [reloadBalances, setReloadBalances] = useState(false);

  const switchNetworkWalletConnect = () => {
    switchChain({ chainId: network === 'bsc' ? 97 : 84532 });
  }

  useEffect(() => {
    if (wallet_address && (network === 'bsc' || network === 'ethereum')) {
      switchNetworkWalletConnect()
    }
    if (wallet_address && (currentBalanceETH?.formatted || currentBalanceBSC?.formatted)) {
      setUsdtBalance(network === 'bsc' ? parseFloat(currentBalanceBSC?.formatted) : parseFloat(currentBalanceETH?.formatted))
    }
  }, [wallet_address, network, currentBalanceETH, currentBalanceBSC, call])


  const {
    connectMetamask,
    walletAddress,
    disconnectWallet,
    signer,
    switchNetwork,
    setWalletAddress
  } = useWalletMetaMask();
  const { fetchUsdtBalance, buyTokens, addressToUsdt, buyTokenWallet } = useContract(signer);

  useEffect(() => {
    if (network === 'tron') {
      const walletType = localStorage.getItem('walletConnect');
      if (walletAddress && !walletType) {
        fetchUsdtBalance(walletAddress || wallet_address, network).then((balance) => {
          setUsdtBalance(balance);
        });
      }
    }
  }, [fetchUsdtBalance, walletAddress, network, reloadBalances, wallet_address]);

  useEffect(() => {
    const fetchProof = async () => {
      setProof([])
      try {
        let response = await fetch(
          `${API_BASE_URL}/public/proof?address=${walletAddress || wallet_address}`
        );

        response = await response.json();
        if (response.success) {
          setProof(response.proof);
          setIsAddressWhitelisted(true);
        } else {
          setIsAddressWhitelisted(false);
          setProof([]);
          // setError(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (walletAddress || wallet_address) {
      fetchProof();
    }
  }, [walletAddress, wallet_address]);

  useEffect(() => {
    const savedNetwork = localStorage.getItem("network");

    if (savedNetwork === "tron" && address) {
      setWalletAddress(address);
    }
  }, [address, connected, setWalletAddress, network]);

  useEffect(() => {
    if (network === 'tron') {
      if (walletAddress || wallet_address) {
        addressToUsdt(walletAddress || wallet_address, network).then((balance) => {
          setAmountInvested({ usdt: balance, wow: balance / data.tokenPrice });
        });
      }
    } else if (wallet_address) {
      if (currentvalue) {
        console.log("🚀 ~ useEffect currentvalue ~ currentvalue:", currentvalue)
        const decimals = network === 'ethereum' ? 6 : 18;
        console.log("🚀 ~ useEffect  currentvalue ~ decimals:", decimals)
        const ethValue = Number(currentvalue.toString()) / Math.pow(10, decimals);
        console.log("🚀 ~ useEffect  currentvalue ~ ethValue:", ethValue)
        setAmountInvested({ usdt: ethValue, wow: ethValue / data.tokenPrice });
      }
    } else {
      setAmountInvested({ usdt: 0, wow: 0 });
    }
  }, [addressToUsdt, data.tokenPrice, walletAddress, network, reloadBalances, wallet_address, currentvalue]);

  useEffect(() => {
    if ((wallet_address || walletAddress) && usdtValue > 0 && (Number(currentBalanceETH?.formatted) || Number(currentBalanceBSC?.formatted)) && usdtBalance) {
      validateInput(usdtValue);
    }
  }, [wallet_address, walletAddress, usdtValue, currentBalanceETH, currentBalanceBSC, usdtBalance])

  const onInputChangeHandler = (e) => {
    validateInput(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${API_BASE_URL}/public/presale-offchain-data`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const { data } = await response.json();

        setData({
          ...data,
          totalCapUsdt: data?.totalCapUsdt,
          totalRaisedUsdt: (data?.totalRaisedUsdt).toFixed(2),
          totalCapWow: data?.totalCapWow,
          totalRaisedWow: (data?.totalRaisedWow).toFixed(2),
          minimumPurchaseUsdt: data?.minimumPurchaseUsdt,
          tokenPrice: data?.tokenPrice,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [roundCount, roundData]);

  const savedNetwork = localStorage.getItem("network") || "ethereum";
  useEffect(() => {
    setNetwork(savedNetwork);
    localStorage.setItem("network", savedNetwork);
  }, [savedNetwork]);

  const handleNetworkChange = async (e) => {
    const selectedNetwork = e.target.value;
    const walletType = localStorage.getItem('walletConnect');
    if (network === selectedNetwork) return;

    // 1) If user was on Tron and is now switching to BSC/Ethereum, disconnect Tron
    if (network === "tron" && (selectedNetwork === "bsc" || selectedNetwork === "ethereum")) {
      try {
        disconnect();
        localStorage.removeItem("walletAddress");
      } catch (err) {
        console.error("Failed to disconnect Tron:", err);
      }
      setUsdtBalance(0);
      setWalletAddress(null);
    }

    // 2) If user was on BSC/Ethereum and is now switching to Tron, disconnect EVM
    if ((network === "bsc" || network === "ethereum") && selectedNetwork === "tron") {
      disconnectWallet();
      try {
        localStorage.removeItem("walletAddress");
      } catch (err) {
        console.error("Failed to disconnect EVM wallet:", err);
      }
      setUsdtBalance(0);
      setWalletAddress(null);
    }

    // 3) Only switch EVM networks if it’s "bsc" or "ethereum"
    if ((selectedNetwork === "bsc" || selectedNetwork === "ethereum") && !walletType) {
      disconnect();
      await switchNetwork(selectedNetwork);
    } else if (walletType) {
      switchNetworkWalletConnect()
    }

    setNetwork(selectedNetwork);
    setAmountInvested({ usdt: 0, wow: 0 })
    if (refetchRound) {
      setTimeout(async () => {
        await refetchRound();
        if (roundCount !== undefined) {
          await refetchRoundActive();
        }
        setLoadingRound(false);
      }, 1000); // Give it a second to update
    }
    localStorage.setItem("network", selectedNetwork);
  };

  const maxClickHandler = () => {
    if (walletAddress || wallet_address) {
      setError("");
      setUsdtValue(usdtBalance);
      setWowValue((usdtBalance / data.tokenPrice).toFixed(3));
    } else {
      setError("Wallet Not Connected");
    }
  };

  const onBuyClick = () => {
    if (!usdtValue) {
      setError("Please enter the USDT amount");
      return false;
    }
    const walletType = localStorage.getItem('walletConnect');
    const walletContactTron = localStorage.getItem('walletContactTron');
    if (network === 'tron' && walletContactTron !== 'tron') {
      buyTokens(usdtValue, proof).then(async (res) => {
        if (res?.status) {
          toast.success("Tokens purchased successfully!!!");
          setData({
            ...data,
            totalRaisedUsdt: Number(data.totalRaisedUsdt) + Number(usdtValue),
            totalRaisedWow: Number(data.totalRaisedWow) + Number(wowValue),
          });
          setReloadBalances((prev) => !prev);
        } else {
          if (!walletType) {
            toast.error(res?.message.substring(0, 50) + "...");
          }
        }
        setLoading(false);
      })
    } else {
      setLoading(true);
      const decimals = CONTRACT_ADDRESSES[network]?.DECIMALS;
      const formattedValue = parseUnits(usdtValue?.toString(), decimals);
      const allowanceUpdate = Number(allowancevalue?.toString());
      // const allowanceUpdate = 0;

      if (allowanceUpdate < parseInt(formattedValue)) {
        writeContract({
          abi: ABIS.ERC20_CONTRACT_ABI,
          address: CONTRACT_ADDRESSES[network]?.USDT_TOKEN,
          functionName: 'approve',
          args: [CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT, MaxUint256],
          // value: formattedValue
        })
        setValues(formattedValue)
        setCall(true);
      } else if (allowanceUpdate > parseInt(formattedValue)) {
        writeContract({
          abi: ABIS.PRESALE_CONTRACT_ABI,
          address: CONTRACT_ADDRESSES[network]?.PRESALE_CONTRACT,
          functionName: 'buyTokens',
          args: [formattedValue, proof],
          // value: values
        })
      }
    }
  };

  const validateInput = (usdtValue) => {
    setError("");
    setUsdtValue(usdtValue);
    if (loadingRound || !roundData) {
      return true;
    }
    if (data?.tokenPrice) {
      setWowValue((usdtValue / data?.tokenPrice).toFixed(3));
      if (isNaN(usdtValue)) {
        setError("Invalid input. Please enter a valid number for USDT amount");
        return false;
      }
      if (walletAddress) {
        if (!usdtValue) {
          setError("Please enter the USDT amount");
          return false;
        }
        if (Number(usdtBalance) < Number(usdtValue)) {
          setError("Insufficient USDT balance");
          return false;
        }
        if (usdtValue < data?.minimumPurchaseUsdt) {
          setError(
            `Amount must be greater than ${data?.minimumPurchaseUsdt} USDT`
          );
          return false;
        }
        if (roundData && roundData?.[1]) {
        const roundAllocation = roundData?.[1] ? parseFloat(ethers.formatEther(roundData?.[1])) : 0;
        const stageRaised = (data?.stageData && data?.stageData[roundCount]?.usdtRaised) || 0;
        const remainingAllocation = roundAllocation - stageRaised;
        
        if (remainingAllocation <= 0) {
          setError("Maximum allocation reached for this round. Cannot purchase more tokens.");
          return false;
        } 
        if (remainingAllocation < parseInt(usdtValue)) {
          setError(`Cannot purchase more than remaining allocation (${remainingAllocation.toFixed(2)} USDT)`);
          return false;
        }
      }
          }
    }
    return true;
  };

  useEffect(() => {
    const updateCountdown = () => {
      const contractEndTimeUTC = Number(endTime); // End time in seconds
      const currentTimeUTC = Math.floor(Date.now() / 1000); // Current UTC time in seconds
      const elapsedTimeAfterEnd = currentTimeUTC - contractEndTimeUTC; // Time passed since end time
      // const twentyFourHours = 24 * 3600; // 24 hours in seconds
      const twentyFourHours = 15 * 60; // 24 hours in seconds
      
      if (currentRoundActive) {
        // When round is active, show buy button
        setStatusMessage("Buy with USDT");
        setRemainingTime(0);
        
        // Handle whitelist period if needed (keeping your existing whitelist logic)
        const whitelistDuration = 15 * 60; // 15 minutes
        if (elapsedTimeAfterEnd < whitelistDuration) {
          setWhitelistStatusMessage(`Whitelisted users only. Public access in ${formatTime(whitelistDuration - elapsedTimeAfterEnd)}`);
          setWhitelistCountdown(whitelistDuration - elapsedTimeAfterEnd);
        } else {
          setWhitelistStatusMessage("Round open to all. Join now!");
          setWhitelistCountdown(0);
        }
      } else {
        // Round is not active
        if (elapsedTimeAfterEnd < twentyFourHours) {
          // Show countdown for 24 hours after end time
          const timeRemaining = twentyFourHours - elapsedTimeAfterEnd;
          setRemainingTime(timeRemaining);
          setStatusMessage(""); // Clear any previous status message
        } else {
          // 24 hours have passed since end time
          setStatusMessage("Current round has ended, Wait for next round to start");
          setRemainingTime(0);
        }
      }
    };
  
    if (!roundLoader) {
      updateCountdown(); // Run once on mount
      const interval = setInterval(updateCountdown, 1000); // Update every second
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [startTime, currentRoundActive, roundvalue, roundCount, roundActiveLoading, roundLoader]);

  // useEffect(() => {
  //   const updateCountdown = () => {
  //     const contractTimeUTC = Number(startTime); // Convert BigInt to number
  //     const startTimeInSeconds = contractTimeUTC; // Already in seconds
  //     const currentTimeUTC = Math.floor(Date.now() / 1000); // Get current UTC time in seconds
  //     const elapsedTime = currentTimeUTC - startTimeInSeconds;

  //     const whitelistDuration = 15 * 60; // 15 minutes (900 seconds)
  //     const firstPhase = 24 * 3600; // First 24 hours (86400 seconds)
  //     const totalDuration = 48 * 3600; // Full 48 hours (172800 seconds)

  //     if (!currentRoundActive || elapsedTime >= totalDuration) {
  //       setStatusMessage("Current round has ended, Wait for next round to start");
  //       setRemainingTime(0);
  //     } else if (elapsedTime >= firstPhase) {
  //       setRemainingTime(totalDuration - elapsedTime); // Start countdown
  //       setStatusMessage("");
  //     } else if (currentRoundActive) {
  //       setStatusMessage("Buy with USDT");
  //       setRemainingTime(0);
  //       if (elapsedTime < whitelistDuration) {
  //         setWhitelistStatusMessage(`Whitelisted users only. Public access in ${formatTime(whitelistDuration - elapsedTime)}`);
  //         setWhitelistCountdown(whitelistDuration - elapsedTime);
  //       } else if (elapsedTime < firstPhase) {
  //         setWhitelistStatusMessage("Round open to all. Join now!");
  //         setWhitelistCountdown(0);
  //       }
  //     }
  //   };
  //   if (!roundLoader) {
  //     updateCountdown(); // Run once on mount
  //     const interval = setInterval(updateCountdown, 1000); // Update every second
  //     return () => clearInterval(interval); // Cleanup on unmount
  //   }
  // }, [startTime, currentRoundActive, roundvalue, roundCount, roundActiveLoading, roundLoader]);

  const formatTime = (seconds) => {
    if (seconds <= 0) return "00h 00m 00s";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h ${minutes}m ${secs}s`;
  };

  const mapNumber = (num) => {
    if (num >= 1 && num <= 8) {
      return 9 - num;
    } else {
      return "Invalid input"; // Handle out-of-range numbers
    }
  };
  // console.log(remainingTime, statusMessage, formatTime(remainingTime), new Date(Number(startTime) * 1000).toUTCString());

  return (
    <React.Fragment>
      {showComingSoonModal && (<ComingSoonModal setShowComingSoonModal={setShowComingSoonModal} />)}
      {!vesting && <div className="text-center">
        <div className="main-container">
          <div className="flex lg:flex-nowrap flex-wrap w-full gap-7">
            <div className="profile-section rounded-xl w-full">
              <div className="intro-wrapper">
                <div className="token-details">
                  <div className="flex justify-between gap-5 flex-wrap lg:flex-nowrap">
                    <div className="rounded-2xl p-2 w-full">
                      <div className="bg-[#265d4e] backdrop-blur-[4px] py-5 px-5 relative rounded-xl">
                        <div className="absolute top-2 right-4">
                          <a target="_blank" href="https://wow-presale.ourwebprojects.pro/#faqs]">
                            <HelpIcon />
                          </a>
                        </div>
                        <div className="text-white text-[16px] font-bold">
                          {whitelistStatusMessage ? whitelistStatusMessage : ''}
                        </div>
                        {/* {whitelistCountdown > 0 && <div>{formatTime(whitelistCountdown)}</div>} */}
                        <div className="flex justify-center pt-4">

                          <div className="text-base mb-3 w-max rounded-full bg-[#0c4538] grid grid-cols-[1fr_auto] md:grid-cols-[1fr_1fr] font-bold text-center text-white shadow-xl">
                            <div className="border-r-2 border-black py-[2px] px-4 bg-wow text-black rounded-tl-full rounded-bl-full">
                              Round <span>{Number(roundCount) ? Number(roundCount) : 0} {statusMessage !== "Buy with USDT" ? "Closed" : ''}</span>
                            </div>
                            <div className="flex items-center bg-[#1c7d75] text-white rounded-tr-full rounded-br-full px-4 py-[2px]">
                              1 USDT = &nbsp; {" "}
                              <img src={logo} className="w-5" alt="Media" />{" "}
                              {/* {data.tokenPrice ? 1 / data?.tokenPrice : 0} */}
                              {(roundData?.[0]
                                  ? (Math.floor((1 / parseFloat(ethers.formatEther(roundData?.[0]))) * 100) / 100).toFixed(2)
                                  : 0)
                              }
                              {Number(roundCount) > 0  && <p className="text-yellow-400 text-[14px]">&nbsp;{` (${Number(roundCount) > 0 ? mapNumber(Number(roundCount)) : 0}% OFF)`}</p>}
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between text-sm md:text-base font-bold text-white">
                          <div>Tokens Sold</div>
                          <div>
                            {(() => {
                              // Make sure roundActive exists and has necessary values
                              if (!roundData) {
                                return "0.00 / 0.00 (0.00%)"; // Default display when data is missing
                              }

                              try {

                                const tokenPrice = parseFloat(
                                  ethers.formatEther(roundData?.[0])
                                );

                                const usdtAllocation = parseFloat(
                                  ethers.formatEther(roundData?.[1])
                                );

                                // Expected tokens from USDT allocation 
                                const expectedTokens = usdtAllocation / tokenPrice;

                                // Calculate tokens sold from USDT raised for the current round
                                const usdtRaised = data?.stageData && data?.stageData[roundCount]?.usdtRaised 
                                  ? parseFloat(data.stageData[roundCount].usdtRaised)
                                  : 0;
                                
                                // Calculate tokens sold by dividing USDT raised by token price
                                const tokensSold = tokenPrice > 0 ? usdtRaised / tokenPrice : 0;

                                // Calculate percentage 
                                const percentage = tokensSold > 0 && expectedTokens > 0
                                  ? (tokensSold / expectedTokens * 100)
                                  : 0;

                                // Format only for display - truncate instead of round
                                const formatNumberWithTruncation = (num, decimals = 2) => {
                                  const factor = Math.pow(10, decimals);

                                  const numStr = num.toString();
                                  const decimalPointIndex = numStr.indexOf('.');
                                  
                                  if (decimalPointIndex === -1) return numStr + '.00';
                                  
                                  const truncated = numStr.substring(0, 
                                    decimalPointIndex + decimals + 1 > numStr.length 
                                      ? numStr.length 
                                      : decimalPointIndex + decimals + 1
                                  );
                                  
                                  const paddingNeeded = decimals - (truncated.length - decimalPointIndex - 1);
                                  return truncated + '0'.repeat(Math.max(0, paddingNeeded));
                                };

                                return `${formatNumberWithTruncation(tokensSold)} / ${formatNumberWithTruncation(expectedTokens)} (${formatNumberWithTruncation(percentage)}%)`;
                              } catch (error) {
                                console.error("Error formatting values:", error);
                                return "0.00 / 0.00 (0.00%)"; // Handle any formatting errors
                              }
                            })()}
                          </div>
                        </div>

                        {/* <div className="progress w-full mt-3">
                          <div
                            className="progress-bar text-sm md:text-base font-semibold"
                            role="progressbar"
                            style={{
                              width:
                                (
                                  (data?.totalRaisedUsdt / data?.totalCapUsdt) *
                                  100
                                ).toString() + "%" || "0%",
                            }}
                            aria-valuenow={88}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <div className="progress-percentage">
                              USDT Raised: &nbsp;{" "}
                              <span className="text-[#1a7b1e]">{`${(roundData?.[3] &&
                                  parseFloat(
                                    network === 'ethereum'
                                      ? ethers.formatUnits(roundData?.[3], 6)
                                      : ethers.formatEther(roundData?.[3])
                                  ).toFixed(2)) || 0
                                } / ${roundData?.[1] &&
                                parseFloat(
                                  network === 'ethereum'
                                    ? ethers.formatUnits(roundData?.[1], 6)
                                    : ethers.formatEther(roundData?.[1])
                                ) || 0
                                }`}
                              </span>
                            </div>
                          </div>
                        </div> */}
                        <div className="mt-3 w-full">
                          <CustomProgressBar
                            completed={(data?.stageData && data?.stageData[roundCount]?.usdtRaised) || 0}
                            maxComplete={roundData?.[1] &&
                              parseFloat(
                                ethers.formatEther(roundData?.[1])
                              ) || 0}
                            height="25px"
                            baseBgColor="#1D373D"
                            bgColor="#48FFCF"
                            labelColor="#0A8569"
                          />
                        </div>

                        <div className="mt-3 mb-2">
                          <p className="mb-1 text-[18px] text-white font-semibold text-left">
                            Network
                          </p>
                          <div className="radio-button-container">
                            <div className="radio-button">
                              <input
                                type="radio"
                                className="radio-button__input"
                                id="ethereum"
                                name="network"
                                value="ethereum"
                                onChange={handleNetworkChange}
                                checked={network === "ethereum"}
                              />
                              <label
                                className="radio-button__label"
                                htmlFor="ethereum"
                              >
                                <span className="radio-button__custom" />
                                ETH
                              </label>
                            </div>
                            <div className="radio-button">
                              <input
                                type="radio"
                                className="radio-button__input"
                                id="bsc"
                                name="network"
                                value="bsc"
                                onChange={handleNetworkChange}
                                checked={network === "bsc"}
                              />
                              <label
                                className="radio-button__label"
                                htmlFor="bsc"
                              >
                                <span className="radio-button__custom" />
                                BSC
                              </label>
                            </div>
                            {/* <div className="radio-button">
                              <input
                                type="radio"
                                className="radio-button__input"
                                id="tron"
                                name="network"
                                value="tron"
                                onChange={handleNetworkChange}
                                checked={network === "tron"}
                              />
                              <label
                                className="radio-button__label"
                                htmlFor="tron"
                              >
                                <span className="radio-button__custom" />
                                TRON
                              </label>
                            </div> */}
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="group fields w-full">
                            <div className="relative w-full flex flex-col">
                              <Input
                                label={"Enter USDT Amount to Invest"}
                                name={"ethValue"}
                                placeholder={`Minimum: ${data.minimumPurchaseUsdt} USDT`}
                                onChange={onInputChangeHandler}
                                hasButton={true}
                                buttonClickHandler={maxClickHandler}
                                value={usdtValue}
                                errorMessage={error}
                              />
                            </div>

                            <div className="flex justify-between items-center pt-2">
                              <p className="text-sm font-bold text-white">
                                USDT
                              </p>
                              <p className="text-base font-bold text-white flex gap-1 items-center">
                                <img src={usdt} className="w-5" alt="Media" />
                                {usdtBalance && Number(usdtBalance)?.toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-2">
                          <div className="flex w-full items-start justify-between">
                            <p className="text-left text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
                              <span className="text-wow">WOW</span> Tokens
                              You&#39;ll Receive:
                            </p>
                            <p className="flex justify-end gap-1 text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
                              <img src={logo} className="w-5" alt="Media" />
                              {(usdtValue && roundData?.[0])
                              ? (Math.trunc((Number(usdtValue) * (1 / parseFloat(
                                  ethers.formatEther(roundData?.[0])
                                ))) * 100) / 100).toFixed(2) 
                              : 0}   
                                </p>
                          </div>
                        </div>

                        <div className="details py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] flex gap-2 items-center text-white capitalize">
                              Min buy
                              <p className="cursor-pointer" title="Min Buy">
                                <InfoIcon />
                              </p>
                            </p>
                            <p className="text-[18px] flex items-center gap-1 text-white">
                              <img src={usdt} className="w-5 h-5" alt="Media" />{" "}
                              {roundData?.[4] && parseFloat(
                                ethers.formatEther(roundData?.[4])
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>

                        <div className="details py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] flex gap-2 items-center text-left text-white capitalize">
                              Your Current Balance
                              <p
                                className="cursor-pointer"
                                title="WOW balance you&#39;ve earned so far"
                              >
                                <InfoIcon />
                              </p>
                            </p>
                            <p className="text-[18px] flex whitespace-nowrap text-end items-center gap-1 text-white">
                              <span className="flex items-center">
                              {`($${amountInvested.usdt && Number(amountInvested.usdt)?.toFixed(2)}) ${(amountInvested.usdt && roundData?.[0])
                                ? (Math.trunc((Number(amountInvested.usdt) * (1 / parseFloat(
                                    ethers.formatEther(roundData?.[0])
                                  ))) * 100) / 100).toFixed(2) 
                                : 0
                              } WOW`}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="details pt-[10px]">
                          {(walletAddress || wallet_address) && (
                            <div className="flex justify-between items-center">
                              <p className="text-[18px] text-left text-white capitalize">
                                Wallet Address
                              </p>
                              <p className="text-[18px] flex whitespace-nowrap text-end items-center gap-2 text-white">
                                <span className="text-wow">
                                  <MiddleTruncate
                                    text={walletAddress || wallet_address}
                                    maxLength={12}
                                  />
                                </span>
                                <button className="px-2 py-1 rounded-md bg-primary-gradient hover:bg-hover-gradient">
                                  <LogoutIcon
                                    disconnectWallet={() => {
                                      setAmountInvested({ usdt: 0, wow: 0 })
                                      setIsAddressWhitelisted(true);
                                      setUsdtBalance(0);
                                      setProof([]);
                                      disconnectWalletConnect();
                                      disconnectWallet();
                                    }}
                                  />
                                </button>
                              </p>
                            </div>
                          )}
                        </div>
                        <div>
                          <span className="text-yellow-400 text-sm">
                            {whitelistCountdown > 0 && !isAddressWhitelisted && (walletAddress && wallet_address) &&
                              "Address not found in whitelist"}
                          </span>
                        </div>

                        <div className="">
                          {(walletAddress || wallet_address) ? (
                            <button
                            disabled={
                              error || loading
                              || (whitelistCountdown > 0 && !isAddressWhitelisted)
                              || !roundData?.[5] || statusMessage !== "Buy with USDT"
                              || ((data?.stageData && roundCount && data?.stageData[roundCount]?.usdtRaised) >= (roundData?.[1] ? parseFloat(ethers.formatEther(roundData?.[1])) : 0))
                            }
                            onClick={() => onBuyClick()}
                            className={`p-3 ${error || loading
                              || (whitelistCountdown > 0 && !isAddressWhitelisted)
                              || !roundData?.[5] || statusMessage !== "Buy with USDT"
                              || ((data?.stageData && roundCount && data?.stageData[roundCount]?.usdtRaised) >= (roundData?.[1] ? parseFloat(ethers.formatEther(roundData?.[1])) : 0))
                              ? "bg-gray-500"
                              : "bg-hover-gradient"
                              }  ${!error &&
                              !loading &&
                              (whitelistCountdown > 0 && isAddressWhitelisted) &&
                              roundData?.[5] && statusMessage === "Buy with USDT" &&
                              !((data?.stageData && roundCount && data?.stageData[roundCount]?.usdtRaised) >= (roundData?.[1] ? parseFloat(ethers.formatEther(roundData?.[1])) : 0)) &&
                              "hover:shadow-pump"
                              } min-h-11 rounded-xl w-full mt-3 text-base font-bold text-white`}
                          >
                            {(isConfirming || loading || loadingRound || roundLoader) ?
                              <LoadingIcon /> : remainingTime > 0 ?
                                "Next round in " + formatTime(remainingTime)
                                : ((data?.stageData && data?.stageData[roundCount]?.usdtRaised) >= 
                                    (roundData?.[1] ? parseFloat(ethers.formatEther(roundData?.[1])) : 0)) ?
                                  "Maximum allocation reached"
                                  : (
                                    <p className="">{statusMessage}</p>
                                  )
                            }
                          </button>
                          ) : (
                            <button onClick={() => setShowWalletModal(true)} className="text-base font-bold mt-3 w-full min-h-11 rounded-xl bg-hover-gradient text-white relative overflow-hidden group z-10 hover:text-white duration-1000">
                              <span className="absolute bg-emerald-600 w-full h-36 rounded-xl group-hover:scale-100 scale-0 -z-10 left-0 -top-10 group-hover:duration-500 duration-700 origin-center transform transition-all" />
                              <span className="absolute bg-emerald-800 w-full h-36 left-0 -top-10 rounded-xl group-hover:scale-100 scale-0 -z-10 group-hover:duration-700 duration-500 origin-center transform transition-all" />
                              CONNECT WALLET
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      {showWalletModal && (
        <WalletModal
          setShowWalletModal={setShowWalletModal}
          connectMetamask={async () => await connectMetamask(network)}
        />
      )}
      {vesting &&
        <ClaimToken />
      }
    </React.Fragment>
  );
}
